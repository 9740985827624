export default {
  primary: {
    en: [
      {
        label: 'Main Event',
        href: '/en/noco/',
        title: 'Main Event',
      },
      {
        label: 'KidzFest',
        href: '/en/noco/kidzfest',
        title: 'KidzFest',
      },
      {
        label: 'Pre-events',
        href: '#',
        title: 'Pre-events',
        items: [
          {
            label: 'Christian Life & Witness Course',
            href: '/en/noco/course/',
            title: 'Christian Life & Witness Course',
          },
          {
            label: 'Pursuit',
            href: '/en/noco/pursuit/',
            title: 'Pursuit',
          },
          {
            label: 'Night of Prayer and Worship',
            href: '/en/noco/prayer-worship-night/',
            title: 'Night of Prayer and Worship',
          },
          {
            label: 'Bring A Friend',
            href: '/en/noco/bring-a-friend/',
            title: 'Bring A Friend',
          },
          {
            label: 'All Pre-events',
            href: '/en/noco/events/',
            title: 'Pre-events',
          },
        ],
      },
      // {
      //   label: 'About',
      //   href: '/en/about',
      //   title: 'About',
      // },
      {
        label: 'Get Involved',
        href: '/en/noco/get-involved',
        title: 'Get Involved',
      },
      {
        label: 'Resources',
        href: '/en/noco/resources',
        title: 'Resources',
      },
      {
        label: 'Give',
        href: 'https://giving.billygraham.org/noco-look-up-celebration/',
        title: 'Give',
      },
    ],
    es: [
      {
        label: 'Evento Principal',
        href: '/es/noco/',
        title: 'Evento Principal',
      },
      {
        label: 'PRÓXIMOS EVENTOS',
        href: '/es/noco/eventos/',
        title: 'PRÓXIMOS EVENTOS',
        items: [
          // {
          //   label: 'Todos Pré-Eventos',
          //   href: '/es/noco/eventos/',
          //   title: 'Todos Pré-Eventos',
          // },
          // {
          //   label: 'I Am Andrew',
          //   href: '/pt/i-am-andrew/',
          //   title: 'I Am Andrew',
          // },
          // {
          //   label: 'Curso de Vida e Testemunho Cristão',
          //   href: '/es/noco/curso/',
          //   title: 'Curso de Vida e Testemunho Cristão',
          // },
        ],
      },
      // {
      //   label: 'Voluntários',
      //   href: '/es/noco/voluntarios',
      //   title: 'Voluntários',
      // },
      // {
      //   label: 'Sobre',
      //   href: '/es/noco/sobre',
      //   title: 'Sobre',
      // },
      // {
      //   label: 'Recursos',
      //   href: '/pt/recursos',
      //   title: 'Recursos',
      // },
    ],
  },
  footer: {
    en: [
      {
        label: 'Privacy Policy',
        href: '/en/privacy',
        title: 'Privacy Policy',
      },
      {
        label: 'Contact Us',
        href: 'https://billygraham.org/about/contact/',
        title: 'Contact Us',
      },
    ],
    es: [
      {
        label: 'Política de Privacidade',
        href: '/es/politica-de-privacidade/',
        title: 'Política de Privacidade',
      },
      {
        label: 'Contacte-nos',
        href: '/es/contacte-nos',
        title: 'Contacte-nos',
      },
    ],
  },
};
